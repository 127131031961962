








































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { CreateVaultViewmodel } from '@/modules/vault/viewmodel/create-vault-viewmodel'

@Observer
@Component({
  components: {
    'time-line': () => import('@/modules/vault/components/create/time-line.vue'),
    parameters: () => import('@/modules/vault/components/create/parameters.vue'),
    tokenomics: () => import('@/modules/vault/components/create/tokenomics.vue'),
    'token-valuation': () => import('@/modules/vault/components/create/token-valuation.vue'),
    'token-contribution': () => import('@/modules/vault/components/create/token-contribution.vue'),
    'send-token-and-service-fee': () => import('@/modules/vault/components/create/send-token-and-service-fee.vue'),
    'contract-whitelist': () => import('@/modules/vault/components/create/contract-whitelist.vue'),
    'create-success': () => import('@/modules/vault/components/create/create-success.vue')
  }
})
export default class extends Vue {
  @Provide() vm = new CreateVaultViewmodel()
}
