import { aprs } from '@/constants'
import { set, max } from 'lodash-es'
import { action, computed, observable, reaction } from 'mobx'
import moment from 'moment'

export interface Tokenomic {
  type: string
  ref: string
  items: Item[]
}

export interface Item {
  item?: string
  allocation?: number
  initialUnlock?: number
  cliff?: number
  vesting?: number
  vestingType?: VestingType
}

export type VestingType = 'quarterly' | 'monthly'

export interface Valuation {
  title?: string
  value?: number
  required?: boolean
}

export const TOKEN_PRICE = 0.05

export class CreateVaultViewmodel {
  constructor() {
    this.tokenomics = require('@/assets/default_tokenomics.json')
  }

  steps: any[] = [
    {
      id: 1,
      done: false
    },
    {
      id: 2,
      done: false
    },
    {
      id: 3,
      done: false
    },
    {
      id: 4,
      done: false
    },
    {
      id: 5,
      done: false
    },
    {
      id: 6,
      done: false
    }
  ]
  @observable currentStep = 1

  @action.bound nextStep() {
    this.steps = this.steps.map(step => {
      if (step.id === this.currentStep) step.done = true
      return step
    })
    this.currentStep = this.currentStep < 7 ? this.currentStep + 1 : 1
  }

  @action.bound previousStep() {
    this.currentStep = this.currentStep - 1
    this.steps = this.steps.map(step => {
      step.done = step.id < this.currentStep
      return step
    })
  }

  // ===================== PARAMETERS =====================
  aprs = aprs
  @observable apr: any = undefined

  @action.bound onAprSelect(apr: any) {
    this.apr = apr
  }

  // ===================== TOKENOMICS BUILDER =====================
  // ALLOCATION
  @observable allocation = 1000000000
  @action.bound onAllocationChange(val: any) {
    this.allocation = val
  }

  // TENTATIVE
  @observable tentativeDate = moment().format('YYYY-MM-DD')
  @observable tentativeTime = moment().format('HH:mm')
  @computed get tentative() {
    return moment(this.tentativeDate + ' ' + this.tentativeTime, 'YYYY-MM-DD HH:mm').toISOString()
  }

  // TOKENOMIC
  vestingTypes = ['quarterly', 'monthly']
  @observable tokenomics: Tokenomic[] = []
  @observable tokenomicSelected: Tokenomic | undefined = undefined

  @action.bound onTokenomicSelect(tokenomic: Tokenomic) {
    this.tokenomicSelected = tokenomic
  }

  @action.bound onTokenomicSelectedChange(val: any, path: string, index: number) {
    if (this.tokenomicSelected) {
      this.tokenomicSelected = set(this.tokenomicSelected, `items[${index}].${path}`, val)
    }
  }

  // ADD TOKENOMIC ITEM
  @observable newTokenomicItem: Item | undefined = undefined
  @action.bound createNewTokenomicItem() {
    this.newTokenomicItem = {}
  }

  @action.bound onNewTokenomicItemChange(val: any, path: string) {
    if (this.newTokenomicItem) {
      this.newTokenomicItem = set(this.newTokenomicItem, path, val)
    }
  }

  @action.bound saveNewTokenomicItem() {
    if (this.tokenomicSelected && this.newTokenomicItem) {
      let items = this.tokenomicSelected.items
      items = [...items, this.newTokenomicItem]
      this.tokenomicSelected = set(this.tokenomicSelected, 'items', items)
    }
    this.cancelNewTokenomicItem()
  }

  @action.bound cancelNewTokenomicItem() {
    this.newTokenomicItem = undefined
  }

  @computed get totalInitialUnclock() {
    let total = 0
    if (this.tokenomicSelected) {
      this.tokenomicSelected.items.forEach(item => {
        const initialUnlock = item.initialUnlock!
        total = total + initialUnlock
      })
    }
    return total
  }

  @computed get initialMarketCap() {
    return this.allocation * this._tokenPrice * (this.totalInitialUnclock / 100)
  }

  @computed get averageRelease() {
    let duration = 0
    let average = 0
    if (this.tokenomicSelected) {
      this.tokenomicSelected.items.forEach(item => {
        const cliff = item.cliff!
        const vesting = item.vesting!
        const durationMonth = cliff + vesting
        if (durationMonth > duration) duration = durationMonth
      })
      average = 1 / (duration * 30)
    }
    return (average * 100).toFixed(3)
  }

  @computed get highestRelease() {
    let items = this.tokenomicSelected?.items as any[]
    if (items) {
      items = items.map(item => {
        const something = 100 - item.initialUnlock!
        const turn = item.cliff! === 0 ? 1 : item.vesting! / item.cliff!
        return { ...item, cliffPercentage: something / turn }
      })
      return max(items.map(x => x.initialUnlock))?.toString() + ', ' + max(items.map(x => x.cliffPercentage))?.toString()
    }

    return 0
  }

  @computed get _tokenPrice() {
    let tokenP = 0
    const publicValuation = this.valuations[2].value
    if (publicValuation && this.allocation) {
      tokenP = publicValuation / this.allocation
    }
    return tokenP
  }

  // VALUETION
  @observable valuations: Valuation[] = [
    {
      title: 'Seed Round',
      value: undefined,
      required: false
    },
    {
      title: 'Private Round',
      value: undefined,
      required: false
    },
    {
      title: 'Public / IDO',
      value: undefined,
      required: true
    }
  ]

  @action.bound onValuationChange(val: any, index: number, path: string) {
    this.valuations = set(this.valuations, `[${index}].${path}`, val)
  }

  @action.bound createValuation() {
    this.valuations = [...this.valuations, { title: '', value: undefined, required: true }]
  }

  @action.bound removeValuation(index: number) {
    this.valuations = this.valuations.filter((v, i) => i !== index)
  }

  // ===================== TOKEN VALUATION =====================
  @observable tokenValuation = 0
  @action.bound onTokenValuationChange(val: any) {
    this.tokenValuation = val
  }
}
